<template>
  <!-- <CodeMirror
    ref="editorRef"
    :value="value"
    :options="codemirrorOptions"
  /> -->
  <textarea ref="textareaRef"></textarea>

</template>

<script>
import {
  // computed,
  ref,
  watch,
  toRefs,
  markRaw,
  // toRaw,
  onMounted,
} from 'vue'

import CodeMirror from 'codemirror'

import 'codemirror/mode/yaml/yaml.js'
import 'codemirror/addon/fold/foldcode.js'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/indent-fold.js'
import 'codemirror/addon/dialog/dialog.js'
import 'codemirror/addon/search/searchcursor.js'
import 'codemirror/addon/search/search.js'
import 'codemirror/addon/search/matchesonscrollbar.js'
import 'codemirror/addon/search/jump-to-line.js'
import 'codemirror/addon/scroll/annotatescrollbar.js'

import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/search/matchesonscrollbar.css'

export default {
  name: 'Codemirror',
  props: {
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  emits: ['update:value', 'change', 'blur'],
  setup(props, { emit }) {
    const { value } = toRefs(props)
    const editor = ref()
    const textareaRef = ref()

    const cmOptions = {
      mode: 'yaml',
      lineNumbers: true,
      lineWrapping: true,
      foldGutter: true,
      gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
      extraKeys: {
        'Ctrl-F': 'findPersistent',
        Esc: 'clearSearch',
        Tab: (cm) => {
          const spaces = Array(cm.getOption('indentUnit') + 1).join(' ')
          cm.replaceSelection(spaces)
        },
      },
      ...props.options,
    }

    onMounted(() => {
      // Use markRaw to return the original instance to avoid error from being packed as proxy
      // Error: Cannot read property 'chunkSize' of undefined
      editor.value = markRaw(CodeMirror.fromTextArea(textareaRef.value, cmOptions))
      editor.value.setValue(value.value)

      editor.value.on('change', (editorIns) => {
        const val = editorIns.getValue()
        emit('change', val)
      })

      editor.value.on('blur', (editorIns) => {
        const val = editorIns.getValue()
        emit('blur', val)
      })
    })

    watch(value, (newVal) => {
      if (newVal !== editor.value.getValue()) {
        editor.value.setValue(newVal)
      }
    })

    watch(() => props.options.readOnly, (newVal) => {
      editor.value.setOption('readOnly', newVal)
    })

    return {
      textareaRef,
    }
  },
}
</script>

<style>
</style>
